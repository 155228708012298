function OpenMenu() {
  $(this).addClass('active').children(".dropdown-menu").addClass('active'); // add class
  $(this).siblings().removeClass('active').children(".dropdown-menu").removeClass('active'); // remove class to other links
  $('body').addClass('dropdown-active'); //add class to body to move content
  $('.remove-menu').fadeIn();
  return false;
}
// LIMIT CHARACTERS
$.fn.textlimit = function(limit) {
  return this.each(function(index, val) {
    var $elem = $(this);
    var $limit = limit;
    var $strLngth = $(val).text().length; // Getting the text
    if ($strLngth > $limit) {
      $($elem).text($($elem).text().substr(0, $limit) + "...");
    }
  });
};

$(document).ready(function() {

  if ($('.post-content table.hidden-xs .image-wrap').length) {
    $('.post-content table.hidden-xs .img-responsive').on('load', function() {
      $(this).closest('.image-wrap').css('max-width', $(this).width() + 'px');
      $(this).closest('table').css('table-layout', 'auto');
//       console.log( $(this).width() );
    });
    setTimeout(function() {
        $('.post-content table.hidden-xs .img-responsive').each(function() {
            $(this).closest('.image-wrap').css('max-width', $(this).width() + 'px');
            $(this).closest('table').css('table-layout', 'auto');
        });
    }, 100);
  }

  // fix click for mobile nav
  if ($(window).width() <= 767) {
    $(".dropdown-menu .list-unstyled li, .dropdown-menu .list-unstyled .featured-menu .feat").click(function() {
      var href = $(this).find('a').attr('href');

      window.location = href;
    });
  }

  // OPEN/CLOSE MENU in mobile
  $('.menu-icon a').click(function() {
    $('body').toggleClass('menu-active'); //add class to body to move content
    $('#navbar-menu-link').toggleClass('nav-menu-closed', false);
    $('.remove-menu').fadeIn();
    // DISABLE TOUCH IF MENU IS ACTIVE
    // if ($('body').hasClass('menu-active')) {
    //     $('.page-wrapper').bind('touchmove', function(e) {
    //         e.preventDefault();
    //     });

    // } else {
    //     $('.page-wrapper').unbind('touchmove');
    // }
    return false;
  });
  $(".back-menu").click(function() {
    if ($(window).width() <= 320) {
      showMenuItems('.nav.nav-stacked');
    }

    $('body').removeClass('dropdown-active');
    $('.navigation li').removeClass('hover');
    $(this).siblings().removeClass('active').children(".dropdown-menu").removeClass('active'); // remove class to other links
    //$('.side-menu').removeClass('disable-scrolling');
    //$('.page-wrapper').removeClass('disable-scrolling');
    $('.credits-mobile').addClass('visible-xs');
    $('.credits-mobile').removeClass('hidden');
    $(".dropdown-submenu").removeClass('opened-submenu');
    return false;
  });
  // CSS
  $(".only-title").parents('.box').css("padding", "20px 30px 20px");
  // SHOW Login

  $(".icon-title").click(function() {
    $(this).parents('.box').find('.box-content').slideToggle();
    $(this).text(function(i, text) {
      return text === "+" ? "-" : "+";
    });
    return false;
  });

  //remove menu completely
  $(".remove-menu").on("tap", function() {
    if ($(window).width() <= 320) {
      showMenuItems('.nav.nav-stacked');
    }

    $('body').removeClass('dropdown-active menu-active');
    $('.navigation li').removeClass('hover');
    $(this).siblings().removeClass('active').children(".dropdown-menu").removeClass('active');
    $('.remove-menu').fadeOut();
    $('.page-wrapper').unbind('touchmove');
    $(".dropdown-menu").removeClass('active');
    $(".dropdown-submenu").removeClass('opened-submenu');
    //$('.side-menu').removeClass('disable-scrolling');
    //$('.page-wrapper').removeClass('disable-scrolling');
  });


  // SIDEBAR OPEN/CLOSE
  $(".related").click(function() {
    loadSidebar();

    $("body").addClass('sidebar-active');
    $(".overlay").fadeIn(100);
    $(".sidebar").css({
      position: 'absolute',
      top: '0px'
    });
    $('.sidebar').animate({
      right: '0px'
    }, 200);
    $(".close-sidebar").delay(300).fadeIn('fast');
    //disable overlay scroll
    $(".overlay").bind('touchmove', function(e) {
      e.preventDefault();
    });

    return false;
  });

  $(".close-sidebar").click(function() {
    if ($(window).width() <= 320) {
      showMenuItems('.nav.nav-stacked');
    }

    $('.sidebar').animate({
      right: '-400px'
    }, 200);
    $(".overlay").delay(400).fadeOut(100);

    $("body").delay(800).queue(function() {
      $(this).removeClass("sidebar-active").dequeue();
    });

    $('.sidebar').css({
      position: 'fixed',
      top: '0px'
    });

    $(this).fadeOut('fast');

    return false;
  });

  function hiddenMenuItems(menuContainer) {
    $(menuContainer).children().each(function () {
      if (!$(this).hasClass('opened-submenu')) {
        $(this).addClass('hidden');
      }
    });
  }

  function showMenuItems(menuContainer) {
    $(menuContainer).children().each(function () {
      $(this).removeClass('hidden');
    });
  }

  // Submenu 100% height
  function mobileCheck() {
    // MENU HOVER
    $(".dropdown-submenu").hover(function() {
      $(this).addClass('opened-submenu');

      if ($(window).width() <= 767) {
        $('.credits-mobile').addClass('hidden');
        $('.credits-mobile').removeClass('visible-xs');
      }

      $(this).children(".dropdown-menu").addClass('visible');
      $(".dropdown-menu").addClass('active');
      $('body').addClass('dropdown-active');
      if ($(window).width() > 320) {
        $('.nav.nav-stacked').addClass('ios-nav-hook');
        $('.side-menu').addClass('ios-menu-hook');
      }
      if ($(window).width() <= 320) {
        hiddenMenuItems('.nav.nav-stacked');
      }
      return false;
    }, function() {
      $(this).removeClass('opened-submenu');
      $('.side-menu').removeClass('ios-menu-hook');
      $('.nav.nav-stacked').removeClass('ios-nav-hook');
      $(this).children(".dropdown-menu").removeClass('visible');

      if ($(window).width() > 320) {
        $('body').removeClass('dropdown-active');
      }


      if ($(window).width() <= 767) {
        $('.credits-mobile').addClass('visible-xs');
        $('.credits-mobile').removeClass('hidden');
      }
    });

    $('.side-menu').mouseleave(function() {
      if ($(window).width() <= 320) {
        showMenuItems('.nav.nav-stacked');
      }
      $(".dropdown-menu").removeClass('active');
    });


    if ($(window).width() >= 1024) {
      $('.in-side-menu').height($(window).height());
      // submenu
      $('.dropdown-menu').height(window.innerHeight - 100);

      // Prevent click if has sub items
      $(".has-sub").click(function() {
        if ($(this).attr('href') == '') {
          return false;
        }
      });
    }

    if ($(window).width() <= 1023) {

      // submenu
      $('.dropdown-menu').height($(window).height() - 100);
      // SHOW SIDEBAR WHEN GET TO BOTTOM (mobile)
      $(function() {
        $(window).scroll(function() {

          if ( $('body').hasClass('sidebar-widget') &&
            ($(window).scrollTop() >= $('.sidebar-widget .article, .sidebar-widget .content-block').offset().top + $('.article, .content-block').outerHeight() - window.innerHeight) ) {
            $('.sidebar-widget').addClass('show-widgets');
            $(".overlay").fadeOut('widgets-mobile');
            $("body").removeClass('sidebar-active');
            $('.sidebar').addClass('widgets-mobile');
            $('.extra.related').fadeOut(200);

            loadSidebar();
          } else {
            $('.sidebar-widget').removeClass('show-widgets');
            $('.sidebar').removeClass('widgets-mobile');
            $('.extra.related').fadeIn(200);

          }
        });
      });
    }
    // end <= 1023

    // STICKY FOOTER
    if ($(window).width() >= 768) {
      //$('.navigation-container').height(window.innerHeight - 100);
      $('.side-menu').height(window.innerHeight - 100);
    }

    if ($(window).width() <= 767) {
      $('#in-side-menu').removeClass("in-side-menu");
      // submenu
      $('.dropdown-menu').height($(window).height() - 40);
      $('.dropdown-submenu').click(OpenMenu);

      // hover class
      $(".navigation li").click(function() {
        $(this).toggleClass('hover');
        $(this).siblings().removeClass('hover');
      });
    }

    // end <= 767
    if ($(window).width() <= 480) {
      //$('.dropdown-menu').height($('.navigation').height() + 58);
      var height = $('.navigation').height() + 60;
      //$('.dropdown-menu').attr('style', 'height:' + height +'px !important');
      if (!$('.mobile-special-table').length) {
        $(".clkexp").textlimit(23);
      }
      $(".magazine-media-list .media-heading").textlimit(315);
      $(".magazine-media-list p").textlimit(140);
    }
    // end <= 480
    if ($('.name-provider-reg').length) {
      $(".navbar-nav .name-provider-reg").textlimit(20);
    } else {
      $(".navbar-nav .name").textlimit(17);
    }
    $(".navbar-nav .name-magazine").textlimit(25);
  }
  // end mobile Check

  // CHANGE HEADER ON SCROLL (article page)
  $(function() {
    var changeHeader = 300;
    $(window).scroll(function() {
      var scroll = getCurrentScroll();
      if (scroll >= changeHeader) {
        $('.single').addClass('change-header');
        $('.extra.related').addClass('while-scroll');
      } else {
        $('.single').removeClass('change-header');
        $('.extra.related').removeClass('while-scroll');
      }
    });

    function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    }
  });

  //ACCORDION
  $('body').on('click', ".clkexp", function() {
//  $(".clkexp").click(function() {
    if ( !$(this).hasClass('provider-info-label') ) {

      if (!$(this).hasClass("expanded")) {
        $(this).next(".cntexp").slideToggle(200);
        $(this).addClass('expanded');
      } else {
        $(this).removeClass('expanded');
        $(this).next(".cntexp").slideUp(200);
      }

    } else if ( $(window).width() < 768 ) {

      if (!$(this).hasClass("expanded")) {
        $(this).next(".cntexp").slideToggle(200);
        $(this).addClass('expanded');
      } else {
        $(this).removeClass('expanded');
        $(this).next(".cntexp").slideUp(200);
      }

      var $providerBlockWrapper = $(this).closest('.provider-block-wrapper');

      $providerBlockWrapper.find('.provider-block').toggleClass('hidden');
      //$providerBlockWrapper.find('.expand.clearfix').toggleClass('without-pt');

    }
  });

  // CUSTOM CHECK
  $('.custom-input').iCheck({
    checkboxClass: 'icheckbox',
    radioClass: 'iradio',
    increaseArea: '20%' // optional
  });
  // CUSTOM SELECT
  $(".custom-select").heapbox({
    'effect': {
      'type': 'slide',
      'speed': 'fast'
    },
    'showFirst': false
  });


  // CAROUSEL
  $('.third-slide').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
  $('.two-slide').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
  $('.first-slide').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  });
  // BOOTSTRAP CAROUSEL
  $('.carousel').carousel({
    interval: false,
    wrap: true
  });

  ///////////////////////////////////////////
  /// CAROUSEL SWIPE
  ///////////////////////////////////////////
  $(".carousel").swiperight(function() {
    $(".carousel").carousel('prev');
  });
  $(".carousel").swipeleft(function() {
    $(".carousel").carousel('next');
  });

  //
  mobileCheck();
  /* Store the window width */
  var windowWidth = $(window).width();

  $(window).on( "orientationchange", function( event ) {
    $('.in-side-menu').height($(window).height());
    $('.dropdown-menu').height($(window).height() - 100);
    mobileCheck();
    windowWidth = $(window).width();
  });

  $(window).resize(function() {
      mobileCheck();

      $('.dropdown-menu').height(window.innerHeight + 80);

      if ($(window).width() != windowWidth) {
        $('body').removeClass('menu-active');
        $('#navbar-menu-link').addClass('nav-menu-closed');
        $('.remove-menu').hide();
      }
  });

});

$(function() {
  if ($(window).width() > 480) {
    $(".has-sub").attr('onclick', '').click(function (e) { e.preventDefault(); });
  }
});

$('.has-sub').bind('singletap', function(e) {
  if ($(window).width() > 1024) {
    var link = $( this).attr('href');

    if (link != '') {
      $(location).attr('href', link);
    }
  }
});

$('.has-sub').bind('doubletap', function(e) {
  var link = $( this).attr('href');

  if (link != '') {
    $(location).attr('href', link);
  }
});

if (typeof Array.isArray === 'undefined') {
  Array.isArray = function(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }
};

if(window.location.hash) {
    var found = window.location.hash.match(/^#lid=([A-z0-9]+)\.([A-z0-9]+)/);

    if (found && found[1] && found[2] && tags) {
        if (!Array.isArray(tags)) {
            var tagsArray = tags.split(',');
        } else {
            var tagsArray = tags;
        }

        var listID = found[1];
        var emailID = found[2];

        $.post('/mc_tag_track', {
            listID: listID,
            emailID: emailID,
            tags: tagsArray
        });
    }
}