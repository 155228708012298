var videoCreditText = '';

// Add youtube video with autoplay video
function autoPlayYoutubeVideo(vcode, width, height) {
    "use strict";
    $("#video-container").html('<iframe style="height: ' + height + 'px;" width="' + width + '" height="' + height + '" src="https://www.youtube.com/embed/' + vcode + '?autoplay=1&loop=0&rel=0&wmode=transparent" frameborder="0" allowfullscreen wmode="Opaque"></iframe>' + videoCreditText);
}

// Add vimeo video with autoplay video
function autoPlayVimeoVideo(vcode, width, height) {
    "use strict";
    $("#video-container").html(
        '<iframe style="height: ' + height + 'px;" width="' + width + '" height="' + height + '" src="https://player.vimeo.com/video/' + vcode + '?api=1&autoplay=1&webkitallowfullscreen&mozallowfullscreen&allowfullscreen" frameborder="0"></iframe>' + videoCreditText);
}

function autoPlayViceVideo(vcode, width, height) {
    "use strict";
    $("#video-container").html(
        '<iframe style="height: ' + height + 'px;" width="' + width + '" height="' + height + '" src="https://video.vice.com/' + vcode + '?autoplay=true" frameborder="0" scrolling="no" allowfullscreen></iframe>' + videoCreditText);
}

function autoPlayFacebookVideo(vcode, width, height) {
    "use strict";
    $("#video-container").html(
        '<iframe style="height: ' + height + 'px;" width="' + width + '" height="' + height + '" src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/' + vcode + '&width=700&show_text=false&height=385&autoplay=true" frameborder="0" scrolling="no" allowfullscreen></iframe>' + videoCreditText);
}

$(document).ready(function () {
    $('#video-play').on('click', function (event) {
        event.preventDefault();

        if ($('#video-play').find('.caption').length) {
            videoCreditText = '<div class="caption">' + $('#video-play').find('.caption').html() + '</div>';
        }

        var videoType = $('#video-type').val();
        var videoId = $('#video-id').val();
        var width = $('#video-container').width();
        var height = $('#video-container').height();

        switch (videoType) {
            case "vimeo":
                autoPlayVimeoVideo(videoId, width, height);
                break;
            case "youtube":
                autoPlayYoutubeVideo(videoId, width, height);
                break;
            case "vice":
                autoPlayViceVideo(videoId, width, height);
                break;
            case "facebook":
                autoPlayFacebookVideo(videoId, width, height);
                break;
        }


        $('#video-play').hide();
    });
});